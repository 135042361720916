<a [routerLink]="hover ? null : getUrl()" (click)="$event.preventDefault()" style="outline: none; border: none; background-color: transparent;" [matMenuTriggerFor]="userMenu">
  <button class="mon-logo-user" [ngClass]="{'active': isActive}" mat-icon-button style="outline: none;
  width: 42px !important;
  text-align: center;
  height: 42px !important;
  justify-content: center;
  display: flex;
  align-items: center;">
    <img width="32" height="32" (error)="handleError($event)" [ngStyle]="{'filter' : profileImage ? 'unset' : null , 'width' : profileImage? '100%' : null,'height' : profileImage? '100%' : null }" [src]="profileImage ?? iconMON" alt="logo">
  </button>
</a>

<!-- User Context Menu -->
<mat-menu [backdropClass]="'cdk-user-header'" #userMenu="matMenu" class="user-context-menu" style="background-color: white;">
  <div class="user-info-header">
    <div class="user-avatar">
      <img [src]="profileImage ?? iconMON" alt="User profile" (error)="handleError($event)">
    </div>
    <div class="user-details">
      <div class="user-name">{{getUserName()}}</div>
      <div class="user-email">{{getUserEmail()}}</div>
    </div>
  </div>
  
  <mat-divider style="margin: 0; border-color: var(--mon-gray-light2);"></mat-divider>
  
  <div class="menu-items" >
    @for (item of menuItems; track item.title) {
      <a style="text-decoration: none; color: inherit;" mat-menu-item [routerLink]="item.route">
        <mat-icon style="color: var(--mon-gray-light);" *ngIf="item.icon">{{item.icon}}</mat-icon>
        <span style=" font-family: Montserrat; color: var(--mon-gray-light);">{{item.title}}</span>
      </a>
    }
  </div>
  
  <mat-divider style="margin: 0; border-color: var(--mon-gray-light2);"></mat-divider>

  <button mat-menu-item (click)="logout($event)">
    <mat-icon style="color: var(--mon-gray-light);">logout</mat-icon>
    <span style=" font-family: Montserrat; color: var(--mon-gray-light);">Logout</span>
  </button>


</mat-menu>
