<footer>
  <section class="apart" style="padding-top: 0;">
    <app-website-title></app-website-title>
    <div class="container-red">
      @for(red of social_red ; track $index){
        <a [href]="red.link" target="_blank">
          <img [alt]="red.title" [src]="red.image">
        </a>
      }
    </div>
    <div class="description">
      <h2 class="company-name">{{companyName}}</h2>
      <address style=" max-width: 165px;">
        {{direction}}
      </address>
      @for(phone of phones ; track $index){
        <a  style="text-decoration: none; color: inherit;" href="tel:{{phone}}">{{phone}}</a><br>
      }
      @for(e of email ; track $index){
        <a  style="text-decoration: none; color: inherit;" href="mailto:{{e}}">{{e}}</a><br>
      }
    </div>
  </section>
  
  <div class="aparts-rest">
    <section class="apart">
      <h3>MY OTHER NEST</h3>
      <ul>
        @for(route of routes_myothernest ; track $index){
          <li>
            <a [href]="route.link">
              {{route.text}}
            </a>
          </li>
        }
      </ul>
    </section>

    <section class="apart">
      <h3>LANDLORDS</h3>
      <ul>
        @for(route of routes_landlords ; track $index){
          <li>
            <a [href]="route.link">
              {{route.text}}
            </a>
          </li>
        }
      </ul>
    </section>

    <section class="apart">
      <h3>HELP CENTER</h3>
      <ul>
        @for(route of routes_helpcenter ; track $index){
          <li>
            <a [href]="route.link">
              {{route.text}}
            </a>
          </li>
        }
      </ul>
    </section>
  </div>
  <div class="footer-2">
    <p style="font-family: Montserrat; font-size: 0.8rem;">© My Other Nest 2024	</p>
    <div style="display: flex; font-weight: 400; font-size: 0.8rem; font-family: Montserrat; align-items: center; color: white; gap: 1em; flex-wrap: wrap;">
      <img loading="lazy"  alt="copyright shield" height="30px" src="/assets/images/footer/footer-copyright-shield.png">
      Secure payment methods:
      <img loading="lazy" height="20px" style="max-width: 90vw;" alt="payment copyright" [src]="image_payments">
    </div>
  </div>
</footer>
