import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { HeaderDashboardComponent } from "../header-dashboard/header-dashboard.component";

@Component({
  selector: 'app-custom-dashboard',
  standalone: true,
  imports: [SidebarComponent, RouterModule, HeaderDashboardComponent],
  templateUrl: './custom-dashboard.component.html',
  styleUrls: ['./custom-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDashboardComponent { }
