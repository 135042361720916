// sidebar.component.ts
import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Injector, runInInjectionContext } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Route, Router, RouterModule, UrlTree } from '@angular/router';
import { MenuGroup } from '@core/interfaces/routes.interface';
import { UserManager } from '@core/managers/UserManager';
import { AGENCY_PORTAL } from '@modules/agency-portal/agency-portal.routes';
import { DASHBOARD } from '@modules/dashboard/dashboard.routes';
import { LANDLORD_DASHBOARD } from '@modules/my-properties/my-properties.routes';
import { PROFILE } from '@modules/my-profile/my-profile.routes';
import { WELCOMEHUB_DASHBOARD } from '@modules/welcome-hub/welcome-hub.routes';
import { DetectChangesServices } from '@services/aside-dashboard/detect-changes.service';
import { SafeHTMLPipe } from "@services/safeHTML.pipe";
import { EXTERNAL } from 'app/app.routes';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-sidebar',
  standalone: true,
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [SafeHTMLPipe, RouterModule, MatTooltipModule]
})
export class SidebarComponent {
  isExpanded = true;
  isMobile : boolean = false;

  menuItems: MenuGroup[] = [];
  deletesSubs : Subscription[] = [];
  parent : string = '/dashboard';
  constructor(private injector: Injector,
    private userManager: UserManager,
      private detectChanges : DetectChangesServices,
      private router : Router,
      private changes : ChangeDetectorRef
    ){
    if(this.userManager.getAuth().hasPermission() || this.userManager.getAuth().isLandlord()){
      this.menuItems.push(
        LANDLORD_DASHBOARD
      )
    }

    if(this.userManager.getAuth().hasPermission() || this.userManager.getAuth().isAgency()){
      // this.menuItems.push(
      //   AGENCY_PORTAL
      // )
    }

    this.menuItems.push(
      // WELCOMEHUB_DASHBOARD,
      DASHBOARD,
      PROFILE
    )

    this.loadGuards();

    this.deletesSubs.push(this.detectChanges.observable().subscribe((detect : boolean)=>{
      if(detect){
        this.loadGuards();
      }
    }))
  }

  async loadGuards(){
    this.guard_info = this.userManager.getAuth().getGuardInfo()

    for(let group of this.menuItems){
      for(let item of group.items){
        await this.loadActive(item)
      }
    }

    this.userManager.getAuth().setGuardInfo(this.guard_info);
  }

  ngOnInit(){
    if(isPlatformBrowser(this.userManager.getPlatformID())){
      this.onResize();
    }
  }

  ngOnDestroy(){
    this.deletesSubs.forEach((dl) => {
      dl.unsubscribe();
    });
  }

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
  }

  toggleMenuItem(menunItem: MenuGroup) {
    if (this.isExpanded) {
      menunItem.expanded = !menunItem.expanded
    }
  }

  logout(event: any) {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
    this.userManager.getAuth().logout();
    window.location.reload();
  }

  isLastStep(item : Route){
    return this._getItem(item,'is_last_step',undefined);
  }
  getStep(item:Route){
    return this._getItem(item,'step',undefined);
  }
  
  getToolTip(item: Route) : string|null{
    if(!item.data) return null;
    if(!('tooltip__off__can_active' in item.data) && !('tooltip__on__can_active' in item.data)) return null;

    if(this.canActive(item)){
      if (!('tooltip__on__can_active' in item.data)) return null;

      return item.data['tooltip__on__can_active']
    }else{
      if (!('tooltip__off__can_active' in item.data)) return null;

      return item.data['tooltip__off__can_active']
    }

    
  }

  get_path(group: MenuGroup,item: Route): string|null {
    if(!this.canActive(item)) return null;
    if(item.path )
      return '/'+EXTERNAL.path+ '/'+group.parent+'/'+item.path 

    return '/'+EXTERNAL.path+ '/'+group.parent
  }

  isActive(link?: string|null): boolean {
    if(!link) return false;
    return this.router.url.startsWith(link);
  }

  private _getItem(item:Route, path : string, dfault : string|undefined = ''){
    if(!item.data) return dfault;
    if(!(path in item.data)) return dfault;

    return item.data[path];
  }

  private guard_info : any = {};
    async loadActive(item : Route) {
      if (!item.canActivate) {
        this.guard_info[item.path!] = true;
        return true;
      }
      if (!item.canActivate.length) {
        this.guard_info[item.path!] = true;
        return true;
      }
     
      const result = await runInInjectionContext(this.injector, async () => {
        for (let guard of item.canActivate!) {
          const rsl = await guard();
        
          if(rsl === false || rsl instanceof UrlTree) return false;
        }
  
        return true;
      });
      
      this.guard_info[item.path!] = result;
      return result;
  }

  canActive(item: Route) : boolean {
    const inf = this.guard_info[item.path!] ?? null;
    if(inf != null && inf != undefined) return inf; 
    
    return false;
  }

   @HostListener('window:resize', ['$event'])
    onResize(event?: Event): void {
      const screenWidth = window.innerWidth;
      this.setScreenType(screenWidth);
    }
    
    private setScreenType(width: number): void {
     
       if (width < 768) {
        this.isMobile = true;
      } else {
       this.isMobile = false;
      }
    }
    

}