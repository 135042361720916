<!-- sidebar.component.html -->
<div class="sidebar" [class.contracted]="!isExpanded">
  <!-- Toggle Button -->
  <button class="toggle-button" (click)="toggleSidebar()">
      @if (isExpanded) {
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
      } @else {
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <polyline points="9 18 15 12 9 6"></polyline>
          </svg>
      }
  </button>

  <!-- Logo -->
  <!-- <div class="logo">
      @if (isExpanded) {
          <span>Menu</span>
      }
  </div> -->

  <!-- Menu Items -->
  <!-- sidebar.component.html -->
<div class="menu-items">
  @for (group of menuItems; track $index) {
      <div class="menu-item">
          @if (isExpanded) {
                <a [routerLink]="isMobile ? group.parent : null" class="menu-title" 
                   (click)="toggleMenuItem(group)"
                   [class.active]="group.expanded">
                  <div class="title-content">
                      <div class="icon-title">
                          <span class="icon-main-menu" [innerHTML]="group.icon | safeHTML"></span>
                          <span class="title">{{group.title}}</span>
                      </div>
                      <svg class="arrow" 
                           [class.rotated]="group.expanded" 
                           width="16" height="16" 
                           viewBox="0 0 24 24" 
                           fill="none" 
                           stroke="currentColor" 
                           stroke-width="2">
                          <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                  </div>
                </a>
              @if (group.expanded) {
                  <div class="submenu">
                      @for (item of group.items; track $index) {
                          <a [routerLink]="get_path(group,item)" 
                          [class.disabled]="!canActive(item)" 
                          [class.active]="isActive(get_path(group,item))"
                        [matTooltip]="getToolTip(item)" matTooltipPosition="above"
                          class="submenu-item">
                              <span class="submenu-icon" [class.step]="getStep(item) ? true : false" [innerHTML]="(item.data ? item.data['icon'] : null) | safeHTML"></span>
                              @if(getStep(item)){
                                
                                <div class="step-line" [class.lastStep]="isLastStep(item)">

                                </div>
                              }
                              <span class="submenu-title">{{item.title}}</span>
                          </a>
                      }
                  </div>
              }
          } @else {
              <div [routerLink]="group.parent" class="menu-icon" [innerHTML]="group.icon | safeHTML "></div>
          }
      </div>
  }
</div>

  <!-- Logout -->
  <div (click)="logout($event)" class="logout">
      @if (isExpanded) {
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
            <polyline points="16 17 21 12 16 7"></polyline>
        </svg>
          <span>Logout</span>
      } @else {
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
          </svg>
      }
  </div>
</div>