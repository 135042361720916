import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { AuthService } from '@services/auth.service';

export const isLandlordGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService.isLogged() || (!authService.hasPermission() && !authService.isLandlord())) {
    router.navigate(['/dashboard'], { onSameUrlNavigation: 'reload' })
    return false;
  }
  return true;
};
