import { Routes } from '@angular/router';
import { SkeletonComponent } from './layouts/skeleton/skeleton.component';
import { authenticationGuard } from '@services/authentication.guard';
import { DASHBOARD } from '@modules/dashboard/dashboard.routes';
import { PROFILE } from '@modules/my-profile/my-profile.routes';
import { isLandlordGuard } from '@services/guards/isLandlord.guard';
import { CustomDashboardComponent } from './layouts/dashboard/custom-dashboard/custom-dashboard.component';
import { AGENCY_PORTAL } from '@modules/agency-portal/agency-portal.routes';
import { isAgencyGuard } from '@services/guards/isAgency.guard';
import { LANDLORD_DASHBOARD } from '@modules/my-properties/my-properties.routes';


export const EXTERNAL = {
  path: 'dashboard',
  component: CustomDashboardComponent,
  canActivate: [authenticationGuard],
  children: [
    {
      path: DASHBOARD.parent,
      loadChildren : () => import('./modules/dashboard/dashboard.module').then((m)=>m.DashboardModule)
    },
    // {
    //   path: WELCOMEHUB_DASHBOARD.parent,
    //   loadChildren : () => import('./modules/welcome-hub/welcome-hub.module').then((m)=>m.WelcomeHubModule)
    // },
    {
      path: LANDLORD_DASHBOARD.parent,
      canActivate: [isLandlordGuard],
      loadChildren : () => import('./modules/my-properties/my-properties.module').then((m)=>m.MyPropertiesModule)
    },
    {
      path: AGENCY_PORTAL.parent,
      canActivate: [isAgencyGuard],
      loadChildren : () => import('./modules/agency-portal/agency-portal.module').then((m)=>m.AgencyPortalModule)
    },
    {
      path: PROFILE.parent,
      loadChildren : () => import('./modules/my-profile/my-profile.module').then((m)=>m.ProfileModule)
    },
    {
      path : '**',
      redirectTo: PROFILE.parent,
    }
  ]
}

export const CUSTOM = {
  path: 'custom',
  component: CustomDashboardComponent,
  canActivate: [authenticationGuard],
  children: [
  ]
}

export const routes: Routes = [
  {
    path: '',
    component: SkeletonComponent,
    loadChildren: () => import('./modules/home/main.module').then((m)=>m.MainModule),
  },
  EXTERNAL,
  CUSTOM,
  {
    path: '**',
    redirectTo: '',
    pathMatch : 'full'
  }
];
