import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { UserManager } from '@core/managers/UserManager';
import { LANDLORD_DASHBOARD } from '@modules/my-properties/my-properties.routes';
import { PROFILE } from '@modules/my-profile/my-profile.routes';
import { UserIconService } from '@services/userIconService';
import { EXTERNAL } from 'app/app.routes';
import { Subscription } from 'rxjs';
import { DASHBOARD } from '@modules/dashboard/dashboard.routes';
import { AGENCY_PORTAL } from '@modules/agency-portal/agency-portal.routes';

interface MenuItem {
  title: string;
  route: string;
  icon?: string;
}

@Component({
  selector: 'app-user-icon',
  standalone: true,
  imports: [
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    RouterModule,
    CommonModule
  ],
  templateUrl: './user-icon.component.html',
  styleUrl: './user-icon.component.scss'
})
export class UserIconComponent implements OnInit {

  @Input() isActive : boolean = true;
  @Input() hover : boolean = true;

  iconMON : string = "/assets/icons/my other nest logos_icono1.png";
  profileImage ?: string|null;
  subs : Subscription []= [];
  urlActive : string = '';
  
  
  // Menu items for the context menu with appropriate routes for Myothernest
  menuItems: MenuItem[] = [
   
   
  ];

  constructor(private userManager : UserManager, private userIconService : UserIconService,private change : ChangeDetectorRef, private router : Router){
    
    this.profileImage = this.userManager.getProfileImage();
    if(this.userManager.isLandlord()){
      this.menuItems.push( { title: 'Properties', route: `/${EXTERNAL.path}/${LANDLORD_DASHBOARD.parent}/listings`, icon: 'real_estate_agent' });
    }
    if(this.userManager.getAuth().isAgency() ){
      this.menuItems.push( { title: 'Portal', route: `/${EXTERNAL.path}/${AGENCY_PORTAL.parent}`, icon: 'other_houses' });
    }

    this.menuItems.push( { title: 'Dashboard', route: `/${EXTERNAL.path}/${DASHBOARD.parent}`, icon: 'dashboard' });
    this.menuItems.push( { title: 'Account', route: `/${EXTERNAL.path}/${PROFILE.parent}`, icon: 'person' });

    this.subs.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          
          this.urlActive = event.urlAfterRedirects;
        }
      })
    )

    this.subs.push(
      this.userIconService.observable().subscribe((update : boolean)=>{

        if(update){
          this.profileImage = this.userManager.getProfileImage();
          this.change.detectChanges();
        }
      })
    );
  }

  logout(event: any) {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
    this.userManager.getAuth().logout();
    window.location.reload();
  }

  getUserName () : string{
    return this.userManager.getUser()?.name ?? 'Unknown User';
  }

  getUserEmail () : string{
    return this.userManager.getUser()?.email ?? 'Unknown Email';
  }

  ngOnInit(): void {
    // Using default values for the demo
    // In a real implementation, you would get this data from your auth service
  }

  getUrl(){
    
    return this.userManager.isLandlord() || this.userManager.getAuth().hasPermission() ?  EXTERNAL.path +"/" + LANDLORD_DASHBOARD.parent + "/listings" :  EXTERNAL.path +"/" + PROFILE.parent;
  }


  ngOnDestroy(){
    this.subs.forEach((s)=>s.unsubscribe());
  } 

  handleError(event: any) {
    delete this.profileImage;
    this.userManager.removeProfileImage();
    event.target.src = this.iconMON;
    event.target.style.objectFit = 'contain';
  }


  private isHoverMenu : boolean = false;
  private _timeoutMenu  : any;

  private isHoverIcon : boolean = false;
  private _timeoutIcon : any ;

  clearTimeouts (){
    if(this._timeoutIcon){
      clearTimeout(this._timeoutIcon);
      delete this._timeoutIcon;
    }

    if(this._timeoutMenu){
      clearTimeout(this._timeoutMenu);
      delete this._timeoutMenu;
    }
  }

  openMenu(menuTrigger: MatMenuTrigger) {
    if(!this.hover)return;
    this.clearTimeouts();
    this.isHoverIcon  = true;
    menuTrigger.openMenu();
  }

  blockMenuTop(){
    this.clearTimeouts();
    this.isHoverMenu = true;
    this.isHoverIcon = false;
  }

  closeMenu(menuTrigger : MatMenuTrigger,level:number = 1){
    if(level == 1){
      
      this._timeoutIcon = setTimeout(()=>{
        this.isHoverIcon = false;
        menuTrigger.closeMenu();
      },100)

      return;
    }
    
    this._timeoutMenu = setTimeout(()=>{
      this.isHoverMenu = false;
      menuTrigger.closeMenu();
    },100)

  }
}
