
<div class="app-container">
  <div class="content-wrapper">
    <app-sidebar></app-sidebar>
    <div style="flex:1;">
      <app-header-dashboard></app-header-dashboard>
      <main class="main-content" id="main-content-wr">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
</div>